/*
路由器对象模块
 */

const MobileTerminal = () => import('@/pages/mobile/mobileTerminal.vue')
const MobileDetail = () => import('@/pages/mobile/mobileDetail.vue')
const MobileBlankTemplate = () => import('@/pages/mobile/mobileBlankTemplate.vue')
const NotFind = () => import('@/pages/404.vue')

import VueRouter from 'vue-router'
import Vue from 'vue'
import store from '../store'
import { routerPermissionJudgment } from '@/util/permissionJudgment/routerPermissionJudgment'

// 声明使用插件
Vue.use(VueRouter)

const router = new VueRouter({
    // 所有路由
    routes: [
        {
            path: '/MobileDetail',
            component: MobileDetail,
            name: 'MobileDetail',
        },
        {
            path: '/MobileBlankTemplate',
            component: MobileBlankTemplate,
            name: 'MobileBlankTemplate',
        },
        {
            path: '/Machine/MobileTerminal',
            component: MobileTerminal,
            name: 'MobileTerminal',
        },
        {
            path: '/NotFind',
            name: 'NotFind',
            component: NotFind,
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    await routerPermissionJudgment(to)

    store.commit('setPercent', 0)
    if (from.name == 'Login' && to.name != 'Reset' && to.name != 'Register') {
        window.location.reload()
    }
    if (
        to.name == 'MobileTerminal' ||
        to.name == 'MobileDetail' ||
        to.name == 'MobileBlankTemplate'
    ) {
        document.getElementsByTagName('html')[0].style.fontSize =
            (document.documentElement.clientWidth / 750) * 100 + 'px'
    } else {
        document.getElementsByTagName('html')[0].style.fontSize =
            (document.documentElement.clientWidth / 1920) * 100 + 'px'
        store.commit('useAntMethode', false)
        if (store.state.useAnt) {
            Vue.use(require('ant-design-vue'))
        }
    }
    next()
})

export default router
