const PREFIX = window.config.needApp ? 'd/terminal/' : 'terminal/'

const apiList = {
    //获取智能资讯和文书生成列表
    getOwl: PREFIX + 'getHomePageModules/themis-owl',
    getDeer: PREFIX + 'getHomePageModules/themis-deer',

    //创建问题
    createQuestionnaire: PREFIX + 'createQuestionnaire',
    //获取下一题
    getNextQuestion: PREFIX + 'getNextQuestion',
    //回退上一题
    getPreQuestion: PREFIX + 'getPreQuestion',
    //获取意见书|文书
    mobileGenerateOpinions: 'terminal/generateOpinions',
    PCGenerateOpinions: PREFIX + 'generateOpinions',
    //获取相似案例
    PCGetCaseIdDetail: PREFIX + 'getCaseIdDetail',
    mobileGetCaseIdDetail: 'terminal/getCaseIdDetail',
    //获取法律法规
    PCGetLegalBasis: PREFIX + 'getLegalBasis',
    mobileGetLegalBasis: 'terminal/getLegalBasis',
    //获取法律法规
    PCLitigationGuidelines: PREFIX + 'litigationGuidelines',
    mobileLitigationGuidelines: 'terminal/litigationGuidelines',

    PCGetInstrumentDetail: PREFIX + 'getInstrumentDetail',
    mobileGetInstrumentDetail: 'terminal/getInstrumentDetail',
    //空白文书模板下载
    getDownload: 'terminal/getDownload',
    //空白模板列表
    classificationPageList: 'terminal/classificationPageList',
    pageList: 'terminal/pageList',

    //获取案由
    selectReason: PREFIX + 'selectReason',

    getSusongExpenseType: PREFIX + 'calculator/susong/expenseType',
    getCalculatorMark: PREFIX + 'calculator/mark',
    calculate: PREFIX + 'calculator',
    getCalculatorParams: PREFIX + 'calculator/getCalculatorParams',
    calculateForRenshen: PREFIX + 'calculator/calculateForRenshen',
    workInjuryDict: PREFIX + 'calculator/workInjuryDict',

    //根据设备码获取token
    resetToken: 'd/inviteCode/bind/resetToken',

    //获取模块列表
    getHomePageTree: PREFIX + 'getHomePageTree',

    //服务到期
    serviceLimit: '/c/serviceLimit',
}

export { apiList }
