const Home = () => import('@/pages/PC/home.vue')
const Index = () => import('@/pages/PC/view/index.vue')
const ReasonList = () => import('@/pages/PC/view/reasonList.vue')
const Question = () => import('@/pages/PC/view/question.vue')
const Result = () => import('@/pages/PC/view/result.vue')
const CalculatorMain = () => import('@/pages/PC/view/calculator/index.vue')
const LegalFare = () => import('@/pages/PC/view/calculator/legalFare/legalFare.vue')
const Version = () => import('@/pages/version.vue')
const InjuryOnTheJob = () => import('@/pages/PC/view/calculator/injuryOnTheJob/injuryOnTheJob.vue')
const Damage = () => import('@/pages/PC/view/calculator/damage/damage.vue')
const Code = () => import('@/pages/code.vue')

export const needAddRoutesMachine = [
    {
        path: '/',
        redirect: '/Machine/Index',
    },
    {
        path: '/version',
        component: Version,
        name: 'Version',
    },
    {
        path: '/Machine',
        component: Home,
        name: 'Home',
        redirect: '/Machine/Index',
        children: [
            {
                path: '/Machine/Index',
                component: Index,
                name: 'Index',
            },
            {
                path: '/Machine/ReasonList',
                component: ReasonList,
                name: 'ReasonList',
            },
            {
                path: '/Machine/Question',
                component: Question,
                name: 'Question',
            },
            {
                path: '/Machine/Result',
                component: Result,
                name: 'Result',
            },
            {
                path: '/Machine/CalculatorMain',
                component: CalculatorMain,
                name: 'CalculatorMain',
                children: [
                    {
                        path: '/Machine/LegalFare',
                        component: LegalFare,
                        name: 'LegalFare',
                    },
                    {
                        path: '/Machine/InjuryOnTheJob',
                        component: InjuryOnTheJob,
                        name: 'InjuryOnTheJob',
                    },
                    {
                        path: '/Machine/Damage',
                        component: Damage,
                        name: 'Damage',
                    },
                    {
                        path: '/Machine/Traffic',
                        component: Damage,
                        name: 'Traffic',
                    },
                ],
            },
        ],
    },
    {
        path: '/code',
        name: 'Code',
        component: Code,
    },
]
