<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'app',
        mounted() {
            let originHeight = document.body.clientHeight

            window.addEventListener(
                'resize',
                () => {
                    setTimeout(() => {
                        let resizeHeight = document.body.clientHeight
                        if (originHeight == resizeHeight) return

                        const { activeElement } = document
                        if (!['INPUT', 'TEXTAREA'].includes(activeElement.tagName.toUpperCase()))
                            return
                        activeElement.scrollIntoView(false)
                    }, 300)
                },
                false
            )
        },
    }
</script>
