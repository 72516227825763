import router from '@/router'
import { codeJudgment } from '@/util/permissionJudgment/codeJudgment'
import { needAddRoutesMachine } from '@/router/needAddRoutesMachine'

export async function routerPermissionJudgment(to) {
    if (
        !router
            .getRoutes()
            .map((res) => res.name)
            .includes(to.name)
    ) {
        if (window.config.needApp) {
            if (window.electronAPI) {
                router.addRoutes(needAddRoutesMachine)
                if (await codeJudgment()) {
                    router.replace({ name: 'Home' })
                } else {
                    router.replace({ name: 'Code' })
                }
            } else {
                router.replace({ name: 'NotFind' })
            }
        } else {
            router.addRoutes(needAddRoutesMachine)
            router.replace(to.fullPath)
        }
    }
}
