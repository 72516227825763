import Vue from 'vue'
import App from './App.vue'
import store from './store'
// import 'ant-design-vue/dist/antd.css'
// import echarts from 'echarts'
import 'ant-design-vue/dist/antd.less'
import './assets/css/theme.less'
import './assets/css/animation.css'
import './assets/css/global.css'
import router from './router'
import axios from 'axios'
import { message, Modal } from 'ant-design-vue'
import { setCookie, getCookie } from '@/util/handleCookie/handleCookie'
import { verifyToken } from '@/service/verifyToken'

// import htmlToPdf from '@/util/html2pdf/htmlToPdf'

// Vue.use(htmlToPdf, {})

Vue.config.productionTip = false

Vue.prototype.bus = new Vue()

if (process.env.NODE_ENV !== 'production') {
    Vue.config.silent = true
}

// Vue.prototype.$echarts = echarts
Vue.prototype.$message = message
Vue.prototype.$axios = axios
//let setPercentTime
axios.defaults.headers.post['Content-Type'] = 'application/json'
// 添加请求拦截器
axios.interceptors.request.use(
    function (config) {
        store.commit('setPercent', 80)
        if (window.config.needApp) {
            config.headers.common['token'] = getCookie('certificate')
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

let modalSum = false
// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        store.commit('setPercent', 100)
        setTimeout(() => {
            store.commit('setPercent', 0)
        }, 300)
        if (response.headers.token && window.config.needApp) {
            setCookie('certificate', response.headers.token, 30)
        }
        if (response.data.returnCode == 401) {
            router.replace({ name: 'Code' })
        }

        return response
    },
    function (error) {
        if (error.response.data.returnCode == 420 && !modalSum) {
            modalSum = true
            Modal.info({
                title: '检测到凭证失效，请重试后重新操作',
                okText: '重试',
                zIndex: 1000000,
                onOk: async () => {
                    await verifyToken()
                    if (router.currentRoute.name == 'HomeMachine') {
                        router.go(0)
                    } else {
                        router.replace({ name: 'HomeMachine' })
                    }
                    modalSum = false
                    store.commit('setSpin', false)
                },
            })
        }
        if (!navigator.onLine) {
            message.error('请检查网络连接')
        }
        store.commit('setSpin', false)
        store.commit('setPercent', 0)

        return error
    }
)

message.config({
    duration: 2,
    maxCount: 1,
})

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount('#app')
