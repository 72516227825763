import * as Server from '@/api/server'
import { apiList } from '@/api/apiList'
export async function verifyToken() {
    let verify = {}
    try {
        let data = {
            method: 'POST',
            url: apiList.resetToken,
            config: {
                data: {
                    macAddress: await window.electronAPI.getMac(),
                },
            },
            loading: false,
        }

        verify = await Server.Axios(data)
    } catch (err) {
        verify = err //捕获失败的promise
    }
    return verify
}
