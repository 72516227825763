import Vue from 'vue'
import Vuex from 'vuex'
import * as Server from '@/api/server'
import { apiList } from '@/api/apiList'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        legalAdvice: null,
        smartInstruments: null,
        percent: 0,
        breadcrumb: 0,
        specific: null,
        tips: false,
        useAnt: true,
        spin: false,
        download: false, //是否显示下载模版
        calculator: null, //计算器模块
    },
    mutations: {
        setPercent(state, data) {
            state.percent = data
        },
        setlegalAdvice(state) {
            state.breadcrumb = state.breadcrumb + 1
        },
        setSpecific(state, data) {
            state.specific = data
        },
        setTips(state, data) {
            state.tips = data
        },
        useAntMethode(state, data) {
            if (state == true) {
                this.useAnt = data
            }
        },
        setSpin(state, data) {
            state.spin = data
        },
    },
    actions: {
        async getLegalAdviceData({ state }, callback = () => {}) {
            if (state.legalAdvice) {
                return
            }
            //请求数据
            let data = {
                method: 'GET',
                url: apiList.getOwl,
                config: {
                    data: {},
                },
                loading: false,
            }
            let dataSource = await Server.Axios(data)

            let result = dataSource.data.returnData
            let resultCopy = []
            result.forEach((el) => {
                if (el.moduleList.length > 0) {
                    el.moduleList.forEach((op) => {
                        op.name = el.moduleName
                    })
                }
                let size = 8 - (el.moduleList.length % 8)
                if (size < 8) {
                    for (let i = 0; i < size; i++) {
                        el.moduleList.push({})
                    }
                }
                resultCopy = resultCopy.concat(el.moduleList)
            })
            state.legalAdvice = resultCopy
            callback()
        },
        async setSmartInstruments({ state }, callback = () => {}) {
            if (state.smartInstruments) return
            //请求数据
            let data = {
                method: 'GET',
                url: apiList.getDeer,
                config: {
                    data: {},
                },
                loading: false,
            }
            let dataSource = await Server.Axios(data)
            if (dataSource.data.returnCode == 500) {
                this.$message.error('服务出错，请稍后重试')
                return
            }
            let result = dataSource.data.returnData
            let resultCopy = []
            result.forEach((el) => {
                if (el.moduleList.length > 0) {
                    el.moduleList.forEach((op) => {
                        op.name = el.moduleName
                    })
                }
                let size = 8 - (el.moduleList.length % 8)
                if (size < 8) {
                    for (let i = 0; i < size; i++) {
                        el.moduleList.push({})
                    }
                }
                resultCopy = resultCopy.concat(el.moduleList)
            })
            state.smartInstruments = resultCopy
            callback()
        },

        // 一起请求三个模块的数据
        async getHomePageData({ state }, callback = () => {}) {
            //请求数据
            let data = {
                method: 'GET',
                url: apiList.getHomePageTree,
                config: {
                    data: {},
                },
                loading: false,
            }
            let dataSource = await Server.Axios(data)

            let limitStatus = 0

            let limitStatusData = {
                method: 'GET',
                url: apiList.serviceLimit,
                config: {
                    data: {},
                },
                loading: false,
            }
            let limitStatusResult = await Server.Axios(limitStatusData)

            limitStatus = limitStatusResult.data.returnData

            //   Server.Axios({
            //         method: 'GET',
            //         url: apiList.serviceLimit,
            //         config: {
            //             data: {},
            //         },
            //         loading: false,
            //     }).then((dataSource) => {
            //         limitStatus = dataSource.data.returnData
            //     })

            let result = dataSource.data.returnData
            let legalAdviceResult = []
            let legalAdviceResultCopy = []
            let smartInstrumentsResult = []
            let smartInstrumentsResultCopy = []
            let calculatorResult = []
            result.forEach((itemResult) => {
                if (itemResult.name == '智能咨询系统') {
                    legalAdviceResult = itemResult.children
                    legalAdviceResult.forEach((item) => {
                        if (item.children.length > 0) {
                            item.children.forEach((op) => {
                                op.moduleId = op.id
                                op.moduleName = op.name
                                op.childrenModules = op.children || null
                                op.name = item.name
                                if (op.id == '103' || op.id == '55' || op.id == '66') {
                                    op.lock = false
                                } else {
                                    op.lock = limitStatus == '3'
                                }

                                if (op.childrenModules && op.childrenModules.length > 0) {
                                    op.childrenModules.forEach((el) => {
                                        el.moduleId = el.id
                                        el.moduleName = el.name
                                        el.childrenModules = el.children || null
                                        el.name = op.moduleName
                                    })
                                }
                            })
                        }
                        let size = 8 - (item.children.length % 8)
                        if (size < 8) {
                            for (let i = 0; i < size; i++) {
                                item.children.push({})
                            }
                        }
                        legalAdviceResultCopy = legalAdviceResultCopy.concat(item.children)
                    })
                }
                if (itemResult.name == '文书生成系统') {
                    if (limitStatus == 3) {
                        itemResult.lock == true
                    }
                    smartInstrumentsResult = itemResult.children
                    smartInstrumentsResult.forEach((item, index) => {
                        if (item.name == '下载文书模板模块') {
                            state.download = true
                            smartInstrumentsResult.splice(index, 1)
                            return
                        }
                    })
                    smartInstrumentsResult.forEach((item, index) => {
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((op) => {
                                op.moduleId = op.id
                                op.moduleName = op.name
                                op.childrenModules = op.children || null
                                op.name = item.name
                                if (op.childrenModules && op.childrenModules.length > 0) {
                                    op.childrenModules.forEach((el) => {
                                        el.moduleId = el.id
                                        el.moduleName = el.name
                                        el.childrenModules = el.children || null
                                        el.name = op.moduleName
                                    })
                                }
                            })
                        }
                        let size = 8 - (item.children.length % 8)
                        if (size < 8) {
                            for (let i = 0; i < size; i++) {
                                item.children.push({})
                            }
                        }
                        smartInstrumentsResultCopy = smartInstrumentsResultCopy.concat(
                            item.children
                        )
                    })
                }
                if (itemResult.name == '案件计算器') {
                    if (limitStatus == 3) {
                        itemResult.lock == true
                    }
                    calculatorResult = itemResult.children
                    calculatorResult.forEach((item) => {
                        item.moduleName = item.name
                        item.name = '案件计算器'
                        switch (item.id) {
                            case '141':
                                item.moduleId = 'LegalFare'
                                break
                            case '143':
                                item.moduleId = 'InjuryOnTheJob'
                                break
                            case '142':
                                item.moduleId = 'Damage'
                                break
                            case '145':
                                item.moduleId = 'Traffic'
                                break
                        }
                    })
                }
            })
            state.legalAdvice = legalAdviceResultCopy
            state.smartInstruments = smartInstrumentsResultCopy
            state.calculator = calculatorResult
            callback()
        },
    },
    modules: {},
})
